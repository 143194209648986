<template>
  <div class="panel-header">
     <div class="update_date">数据最后更新时间：{{last_updated}}</div>
    <!-- <header-chart :height="255"
                  :data="chartData"
                  :labels="chartLabels">
    </header-chart> -->
    <div class="chart-area" id="box-chart"></div>
  </div>
</template>

<script>
//import HeaderChart from './HeaderChart';
import { consts } from "src/util/consts.js";
export default {
  name: 'overview-header',
  components: {
    // HeaderChart
  },
  data() {
    return {
      last_updated: consts.last_updated
    };
  },
  mounted () {
    var box = document.getElementById("box-chart");
    var myChart = this.$echarts.init(box);
    var  option = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['建筑群落', '路网交通', '城市水网', '城市电网'],
            left: 'right',
            textStyle: {
                fontSize: 12,
                color: "#fff"
            }
        },
        grid: {
            top: '40',
            left: '40',
            right: '20',
            bottom: '40',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            name: '时间（周）',
            nameLocation: 'center', 
            nameGap: 30,
            boundaryGap: false,
            data: ['0', '2', '4', '6', '8', '10', '20', '30','40','50','60','70','80','90','100','110','120','130','140'],
            axisLine: {
                lineStyle: {
                    color: "rgba(255,255,255,0.6)"
                }
            },
            axisLabel: {//坐标轴刻度标签的相关设置。
                textStyle: {
                    color: "rgba(255,255,255,0.6)"
                }
            }
        },
        yAxis: {
            type: 'value',
            name: '功能状态',
            nameLocation: 'center',
            nameGap: 40,
            axisLine: {
                lineStyle: {
                    color: "rgba(255,255,255,0.6)"
                }
            },
            axisLabel: {//坐标轴刻度标签的相关设置。
                textStyle: {
                    color: "rgba(255,255,255,0.6)"
                }
            }
        },
        series: [
            {
                name: '建筑群落',
                type: 'line',
                symbol: "none",
                data: [0.01,0.01,0.011,0.0115,0.017,0.1284,0.2072,0.2952,0.5158,0.7141,0.8224,0.8886,0.9352,0.9657,0.9832,0.9923,0.9966,0.9986,0.9994],
                smooth: true,
                color: "#ffd415",
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(255,212,21,0.5)'
                  }, {
                      offset: 1,
                      color: 'rgba(255,212,21,0)'
                  }])
                }
            },
            {
                name: '路网交通',
                type: 'line',
                symbol: "none",
                data: [0.65,0.67,0.68,0.72,0.73,0.82,0.95,1,1,1,1,1,1,1,1,1,1,1,1],
                smooth: true,
                color: "#26db87",
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(38,219,135,0.5)'
                  }, {
                      offset: 1,
                     color: 'rgba(38,219,135,0)'
                  }])
                }
            },
            {
                name: '城市水网',
                type: 'line',
                symbol: "none",
                data: [0.77586,0.87931,0.96552,0.96552,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
                smooth: true,
                color: "#4cf8f7",
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(76,248,247,0.5)'
                  }, {
                      offset: 1,
                     color: 'rgba(76,248,247,0)'
                  }])
                }
            },
            {
                name: '城市电网',
                type: 'line',
                symbol: "none",
                color: "#f84b96",
                data: [0.67213,0.81967,0.88525,0.96721,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
                smooth: true,
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(248,75,150,0.5)'
                  }, {
                      offset: 1,
                      color: 'rgba(248,75,150,0)'
                  }])
                }
            },
        ]
    };
     myChart.setOption(option);

  }
};
</script>

<style scoped>
.panel-header {
  height: 330px;
}
.chart-area {
  padding: 0 15px;
  width: 100%;
  height: 280px;
  margin: 0 auto;
}
.update_date {
  color: #fff;
  position: absolute;
  left: 15px;
  top: 50px;
}
</style>
