<template>
  <div class="dashboard">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-primary">
                      <!-- <i class="now-ui-icons ui-2_chat-round"></i> -->
                      <img src="/img/dashboard/renkou.ico" alt="">
                    </div>
                    <h3 class="info-title">
                      <!-- <animated-number :value="853"></animated-number> -->
                      39万
                    </h3>
                    <h6 class="stats-title">人口</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-success">
                      <!-- <i class="now-ui-icons business_money-coins"></i> -->
                      <img src="/img/dashboard/jiedao.ico" alt="">
                    </div>
                    <h3 class="info-title">
                      <!-- <small>$</small>
                      <animated-number :value="3521"></animated-number> -->
                      6个
                    </h3>
                    <h6 class="stats-title">街道</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-info">
                      <!-- <i class="now-ui-icons users_single-02"></i> -->
                      <img src="/img/dashboard/shequ.ico" alt="">
                    </div>
                    <h3 class="info-title">
                      <!-- <animated-number :value="562"></animated-number> -->
                      54个
                    </h3>
                    <h6 class="stats-title">社区</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card card-city card-building" @click="go('building')">
          <div class="card-title"><img src="/img/dashboard/building.ico" alt=""><span>建筑群落</span></div>
          <div class="card-body">
            <div class="map"></div>
            <div class="info-wrapper">
              <div class="info-item">
                <span class="fl">建筑</span>
                <span class="fr">6400栋</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card card-city card-traffic"  @click="go('traffic')">
          <div class="card-title"><img src="/img/dashboard/traffic.ico" alt=""><span>路网交通</span></div>
          <div class="card-body">
            <div class="map"></div>
            <div class="info-wrapper">
              <div class="info-item">
                <span class="fl">桥梁:</span>
                <span class="fr">20座</span>
              </div>
              <div class="info-item">
                <span class="fl">道路:</span>
                <span class="fr">20条</span>
              </div>
              <div class="info-item">
                <span class="fl">道路长度:</span>
                <span class="fr">3000千米</span>
              </div>
            </div>
          </div>
          <!-- <div class="card-mask"></div> -->
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card card-city card-water"  @click="go('water')">
          <div class="card-title"><img src="/img/dashboard/water.ico" alt=""><span>城市水网</span></div>
          <div class="card-body">
            <div class="map"></div>
            <div class="info-wrapper">
              <div class="info-item">
                <span class="fl">水厂</span>
                <span class="fr">1座</span>
              </div>
              <div class="info-item">
                <span class="fl">泵厂</span>
                <span class="fr">2座</span>
              </div>
              <div class="info-item">
                <span class="fl">管道总长</span>
                <span class="fr">2000千米</span>
              </div>
            </div>
          </div>
          <!-- <div class="card-mask"></div> -->
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card card-city card-electric"  @click="go('electric')">
          <div class="card-title"><img src="/img/dashboard/electric.ico" alt=""><span>城市电网</span></div>
          <div class="card-body">
            <div class="map"></div>
            <div class="info-wrapper">
              <div class="info-item">
                <span class="fl">电厂:</span>
                <span class="fr">1座</span>
              </div>
              <div class="info-item">
                <span class="fl">220KV输电塔:</span>
                <span class="fr">2个</span>
              </div>
              <div class="info-item">
                <span class="fl">110KV输电塔:</span>
                <span class="fr">10个</span>
              </div>
              <div class="info-item">
                <span class="fl">10KV输电塔:</span>
                <span class="fr">40个</span>
              </div>
              
            </div>
          </div>
          <!-- <div class="card-mask"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  StatsCard,
  Card,
  Table as NTable,
  Checkbox,
  AnimatedNumber,
  Progress as NProgress,
  AsyncWorldMap
} from 'src/components';

import LineChart from 'src/components/Charts/LineChart';

export default {
  components: {
    Checkbox,
    Card,
    NTable,
    StatsCard,
    AnimatedNumber,
    LineChart,
    NProgress,
    AsyncWorldMap
  },
  data() {
    return {

    };
  },
  mounted () {
    document.querySelector('.wrapper').classList.add('dashboard');
    document.querySelector('.sidebar .facility').style.display = 'none';
    document.querySelector('.sidebar .sidebar-wrapper').style.display = 'none';
    document.querySelector('.sidebar .left-tip-block').style.display = 'none';
  },
  methods: {
    go(type) {
      if(type == 'building') {
        this.$router.push('/earthquake/building/city-info')
      } else if(type == 'water') {
        this.$router.push('/earthquake/water/basic')
      } else if(type == 'electric') {
        this.$router.push('/earthquake/electric/basic')
      } else if(type == 'traffic') {
        this.$router.push('/earthquake/traffic/basic')
      } else {
        this.$notify({
          message: '此版块暂未开放',
          icon: 'fa fa-gift',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning'
        })
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    document.querySelector('.wrapper').classList.remove('dashboard');
    document.querySelector('.sidebar .facility').style.display = 'block';
    document.querySelector('.sidebar .sidebar-wrapper').style.display = 'block';
    document.querySelector('.sidebar .left-tip-block').style.display = 'block';
    next();
  }
};
</script>
<style scoped lang="scss">
.card-stats {
  background: #ecf3f8;
}
.card-stats .icon img {
  width: 50px;
}
.card-stats .statistics .stats-title {
  color: #000;
}
.card-stats .statistics {
  padding: 0;
}
.card-city {
  background: #344a5e;
  color: #fff;
  padding: 15px 10px;
  min-height: calc(100vh - 505px);
  .card-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 2;
  }
  .card-title {
    margin-bottom: 0;
    img {
      width: 40px;
    }
    span {
      padding-left: 10px;
    }
  }
  .card-body {
    padding: 0; 
  }

  .map {
    width: 100%;
    height: 200px;
  }
  &.card-building {
    cursor: pointer;
  }
  &.card-traffic, &.card-water, &.card-electric {
    
  }
  &.card-building .map{
    background: url(/img/dashboard/building.jpg) 50% 50% no-repeat;
    background-size: cover;

  }
  &.card-traffic .map{
    background: url(/img/dashboard/traffic.jpg) 50% 50% no-repeat;
    background-size: cover;

  }
  &.card-water .map{
    background: url(/img/dashboard/water.jpg) 50% 50% no-repeat;
    background-size: cover;

  }
  &.card-electric .map{
    background: url(/img/dashboard/electric.jpg) 50% 50% no-repeat;
    background-size: cover;

  }
  
  .info-wrapper {
    padding: 5px 0;
    .info-item {
      padding: 6px 0;
      
      border-bottom: 1px solid #8c9d9d;
      overflow: hidden;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
  }
}

</style>

<style lang="scss">
  .wrapper.dashboard {
    
    height: 100vh;
    .info .info-title {
        margin: 0;
      }
    .main-panel {
      height: 100%;
       background: #93a2a3;
      .content {
         background: #93a2a3;
      }
      
     
    }
    // .col-lg-3 {
    //   padding: 0 5px;
    // }
  }
  
</style>
