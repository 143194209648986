var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card card-city card-building",on:{"click":function($event){return _vm.go('building')}}},[_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card card-city card-traffic",on:{"click":function($event){return _vm.go('traffic')}}},[_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card card-city card-water",on:{"click":function($event){return _vm.go('water')}}},[_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card card-city card-electric",on:{"click":function($event){return _vm.go('electric')}}},[_vm._m(7),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-stats card-raised"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"icon icon-primary"},[_c('img',{attrs:{"src":"/img/dashboard/renkou.ico","alt":""}})]),_c('h3',{staticClass:"info-title"},[_vm._v(" 39万 ")]),_c('h6',{staticClass:"stats-title"},[_vm._v("人口")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"icon icon-success"},[_c('img',{attrs:{"src":"/img/dashboard/jiedao.ico","alt":""}})]),_c('h3',{staticClass:"info-title"},[_vm._v(" 6个 ")]),_c('h6',{staticClass:"stats-title"},[_vm._v("街道")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"icon icon-info"},[_c('img',{attrs:{"src":"/img/dashboard/shequ.ico","alt":""}})]),_c('h3',{staticClass:"info-title"},[_vm._v(" 54个 ")]),_c('h6',{staticClass:"stats-title"},[_vm._v("社区")])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title"},[_c('img',{attrs:{"src":"/img/dashboard/building.ico","alt":""}}),_c('span',[_vm._v("建筑群落")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"map"}),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("建筑")]),_c('span',{staticClass:"fr"},[_vm._v("6400栋")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title"},[_c('img',{attrs:{"src":"/img/dashboard/traffic.ico","alt":""}}),_c('span',[_vm._v("路网交通")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"map"}),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("桥梁:")]),_c('span',{staticClass:"fr"},[_vm._v("20座")])]),_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("道路:")]),_c('span',{staticClass:"fr"},[_vm._v("20条")])]),_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("道路长度:")]),_c('span',{staticClass:"fr"},[_vm._v("3000千米")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title"},[_c('img',{attrs:{"src":"/img/dashboard/water.ico","alt":""}}),_c('span',[_vm._v("城市水网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"map"}),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("水厂")]),_c('span',{staticClass:"fr"},[_vm._v("1座")])]),_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("泵厂")]),_c('span',{staticClass:"fr"},[_vm._v("2座")])]),_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("管道总长")]),_c('span',{staticClass:"fr"},[_vm._v("2000千米")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title"},[_c('img',{attrs:{"src":"/img/dashboard/electric.ico","alt":""}}),_c('span',[_vm._v("城市电网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"map"}),_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("电厂:")]),_c('span',{staticClass:"fr"},[_vm._v("1座")])]),_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("220KV输电塔:")]),_c('span',{staticClass:"fr"},[_vm._v("2个")])]),_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("110KV输电塔:")]),_c('span',{staticClass:"fr"},[_vm._v("10个")])]),_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"fl"},[_vm._v("10KV输电塔:")]),_c('span',{staticClass:"fr"},[_vm._v("40个")])])])])
}]

export { render, staticRenderFns }